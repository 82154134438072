<template>
  <div class="content">
    <work-in-progress/>

    <div class="grid-x align-justify">
      <div class="cell shrink">
        <p>Categorías y subcategorías</p>
      </div>
    </div>

    <div class="grid-x align-center">
      <div class="cell medium-6">
        <div class="wrapper">
          <table class="categories-table">
            <thead>
              <tr>
                <th>Categoría</th>
                <th class="shrink">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cat, index) in categoriesList"
                :key="cat.id"
                :class="cat.type">
                <td>
                  <div
                    v-if="!cat.edit"
                    class="category-row"
                    @click="openEditCategory(index)">
                    <dots-vertical-icon v-if="!cat.isCategory"/>
                    {{ cat.title }}
                    <pencil-icon class="edit-icon"/>
                  </div>
                  <div
                    v-else
                    class="input-block">
                    <input
                      :ref="`inputEditTitle${cat.id}`"
                      v-model.trim="categoriesList[index].titleModel"
                      @keyup.enter="editCategory(index)"
                      class="input-title"
                      :class="{ 'has-error': cat.titleHasError }"
                      type="text"/>

                    <button
                      @click="editCategory(index)"
                      class="button-icon success">
                      <check-icon/>
                    </button>

                    <button
                      @click="cancelEditCategory(index)"
                      class="button-icon danger">
                      <close-icon/>
                    </button>
                  </div>
                </td>
                <td>
                  <div v-if="false" class="options-wrapper align-right">
                    <span
                      @click="addSubcategory(index)"
                      v-if="cat.isCategory"
                      class="icon-button"
                      role="button">
                    <plus-circle-icon/>
                  </span>
                    <span
                      @click="deleteClicked(index)"
                      class="icon-button alert"
                      role="button">
                    <trash-can-icon/>
                  </span>
                  </div>
                </td>
              </tr>
              <tr v-if="emptyCategories">
                <td colspan="2">Sin categorías</td>
              </tr>
            </tbody>
          </table>

          <div v-if="false" class="btn-add-wrapper">
            <div
              v-if="showNewCategoryForm"
              class="form">
              <div class="form-group">
                <label
                  for="input-name"
                  class="form-label required">Nueva categoría</label>
                <input
                  ref="inputNewCategory"
                  id="input-name"
                  v-model.trim="inputNewCategory"
                  @keyup.enter="addNewCategory"
                  class="form-input lg"
                  :class="{ 'has-error': inputNewCategoryHasError }"
                  type="text"
                  autocomplete="off"/>

                <button
                  @click="addNewCategory"
                  class="button-icon success">
                  <check-icon/>
                </button>

                <button
                  @click="cancelNewCategory"
                  class="button-icon danger">
                  <close-icon/>
                </button>
              </div>
            </div>
            <div
              v-else
              @click="newCategory"
              class="icon-button"
              role="button">
              <plus-circle-icon/>
            </div>
          </div>
        </div>

        <div v-if="false" class="grid-x align-justify margin-top--20">
          <button
            :disabled="!saveEnabled"
            @click="saveCategories"
            class="button fill primary md">Guardar
          </button>
          <button
            :disabled="!saveEnabled"
            @click="cancelSaveCategories"
            class="button fill alert md">Deshacer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';

export default {
  components: {
    WorkInProgress: () => import('@/components/WorkInProgress.vue'),
  },
  props: {
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      categories: [],
      categoriesList: [],
      showNewCategoryForm: false,
      inputNewCategory: '',
      inputNewCategoryHasError: false,
      categoryUpdates: [],
    };
  },
  computed: {
    emptyCategories() {
      return this.categoriesList.length === 0;
    },
    saveEnabled() {
      return this.categoryUpdates.length > 0;
    },
  },
  watch: {
    campaign(newVal) {
      this.categories = newVal.categories;
      this.updateCategoriesList();
    },
  },
  mounted() {
    this.categories = this.campaign.categories;
    this.updateCategoriesList();
  },
  methods: {
    updateCategoriesList() {
      const list = [];

      this.categories.forEach((c) => {
        list.push({
          ...c,
          edit: false,
          type: 'category',
          isCategory: true,
          parentId: null,
          titleModel: c.title,
          titleHasError: false,
        });
        c.subcategories.forEach(s => list.push({
          ...s,
          edit: false,
          type: 'subcategory',
          isCategory: false,
          parentId: c.id,
          titleModel: s.title,
          titleHasError: false,
        }));
      });

      this.categoriesList = list;
    },
    focusNewCategory() {
      this.$nextTick(() => {
        this.$refs.inputNewCategory.focus();
      });
    },
    newCategory() {
      this.showNewCategoryForm = true;
      this.focusNewCategory();
    },
    addNewCategory() {
      if (this.inputNewCategory.length === 0) {
        this.inputNewCategoryHasError = true;
        this.focusNewCategory();
      } else {
        const data = {
          campaign: this.campaign.id,
          title: this.inputNewCategory,
        };

        this.categoryUpdates.push(data);

        this.cancelNewCategory();
      }
    },
    cancelNewCategory() {
      this.showNewCategoryForm = false;
      this.inputNewCategoryHasError = false;
      this.inputNewCategory = '';
    },
    addSubcategory(index) {
      // TODO implement
      const cat = this.getCategory(index);
      this.categoryUpdates.push(cat);
    },
    deleteClicked(index) {
      this.$swal({
        text: '¿Estás seguro de continuar?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.value) {
            // TODO implement
            const cat = this.getCategory(index);
            this.categoryUpdates.push(cat);
          }
        });
    },
    openEditCategory(index) {
      const cat = this.getCategory(index);
      cat.edit = true;

      this.$nextTick(() => {
        if (this.$refs[`inputEditTitle${cat.id}`] && this.$refs[`inputEditTitle${cat.id}`].length > 0) {
          this.$refs[`inputEditTitle${cat.id}`][0].focus();
        }
      });
    },
    editCategory(index) {
      const cat = this.getCategory(index);

      if (cat.titleModel.length === 0) {
        cat.titleHasError = true;
      } else {
        if (cat.titleModel !== cat.title) {
          const data = {
            id: this.campaign.id,
            categories: [
              {
                id: cat.id,
                title: cat.titleModel,
                parent: cat.parentId,
              },
            ],
          };

          // TODO move to categoryUpdates
          EventBus.$emit('UPDATE_CAMPAIGN', data);
        }

        cat.edit = false;
        cat.titleHasError = false;
      }
    },
    cancelEditCategory(index) {
      const cat = this.getCategory(index);
      cat.edit = false;
      cat.titleHasError = false;
      cat.titleModel = cat.title;
    },
    getCategory(index) {
      return this.categoriesList[index];
    },
    saveCategories() {
      // TODO implement
      this.$emit('save-all-categories');
    },
    cancelSaveCategories() {
      this.$swal({
        text: 'No se guardarán los cambios, ¿deseas continuar?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.value) {
            this.categoryUpdates = [];
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_categories-tab.scss';
</style>
